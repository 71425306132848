.waiting-list-container {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.main-message {
  max-width: 900px;
  font-size: 43px !important;
  line-height: 1.1 !important;
}

.content-container {
  padding: 35px 5%;
  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  margin: auto 0;
  min-height: 40vh;
}

.cta-button {
  margin-top: 30px;
}

@media (max-width: 768px) {
  .content-container {
    padding: 30px 5%;
  }
}

/* Small phones (iPhone SE, etc.) */
@media (max-width: 375px) {
  .content-container {
    padding: 15px 5%;
  }
  .main-message {
    font-size: 21px !important;
    margin: 0;
  }
  .cta-button {
    margin-top: 15px;
    font-size: 16px !important;
    padding: 10px 30px !important;
  }
}

/* Larger phones */
@media (min-width: 376px) and (max-width: 768px) {
  .content-container {
    height: 35% !important;
  }
  .main-message {
    font-size: 22px !important;
  }
  .cta-button {
    margin-top: 15px;
    font-size: 16px !important;
    padding: 10px 30px !important;
  }
}
/* Desktop */
@media (min-width: 769px) {
  .main-message {
    margin-top: 15px !important;
  }
}
