.countdown {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff3e0;
  padding: 20px;
  color: #d97706;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.time {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time span {
  font-size: 20px;
  font-weight: 600;
}
