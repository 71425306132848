.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
}

.modal-content.stripe-checkout .form-field-container {
  padding: 0 20px !important;
  border: none !important;
  box-shadow: none !important;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  padding: 0;
  width: auto;
  box-shadow: none;
}

.modal-content h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: #30313d;
}

.modal-content .form-field-container {
  margin-bottom: 1.5rem;
}

.required {
  color: rgba(223, 27, 65, 0.6);
  font-size: 13px;
  font-weight: 400;
}

.error-message {
  color: #df1b41;
  font-size: 14px;
  margin-top: 4px;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* For mobile responsiveness */
@media only screen and (max-width: 600px) {
  .modal-content {
    width: 90%;
    margin: 20px;
    padding: 33px 1rem;
  }
}

/* Success Animation */
.success-animation {
  text-align: center;
  padding: 40px;
}

.success-checkmark {
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
  border-radius: 50%;
  box-sizing: content-box;
  position: relative;
}

.check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #d67688;
}

.check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}

.check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}

.icon-line {
  height: 5px;
  background-color: #d67688;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

.icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

.icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid #d67688;
}

.icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: white;
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 46px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 8px;
    top: 38px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.waiting-list-form {
  padding: 0 !important;
  width: 100% !important;
}

.form-label {
  display: block;
  color: rgb(48, 49, 61);
  font-family: -apple-system, system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14.88px;
  line-height: 17.112px;
  margin-bottom: 4px;
}

.required-asterisk {
  color: rgba(223, 27, 65, 0.6);
  font-size: 13px;
  font-weight: 400;
}

.form-input {
  display: block;
  width: 100%;
  padding: 11px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.03),
    0px 3px 6px rgba(0, 0, 0, 0.02);
  font-size: 16px;
  margin: 4px 0;
}

.form-input.error {
  border-color: #df1b41;
}

.success-message {
  font-size: 12px;
  color: #666;
  margin-top: 20px;
}

.checkbox-container {
  margin: 16px 0;
  border: none;
  box-shadow: none;
  background: none;
  padding: 0 20px !important;
}

.checkbox-label {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 11px;
  color: #666;
  cursor: pointer;
}

.checkbox-label input[type='checkbox'] {
  margin-top: 2px;
}

.error-message {
  color: #df1b41;
  font-size: 14px;
  margin-top: 4px;
}

.form-row:has(.checkbox-container) {
  border: none;
  box-shadow: none;
  background: none;
  padding: 0;
}

.form-row:has(.checkbox-container) .form-field-container {
  border: none;
  box-shadow: none;
  background: none;
  padding: 0;
}

.checkbox-label.error {
  color: #df1b41;
}

.checking-message {
  font-size: 0.8em;
  color: #666;
  margin-top: 4px;
}

.instagram-profile-preview {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.profile-picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.username {
  font-weight: 600;
  font-size: 14px;
}

.full-name {
  font-size: 12px;
  color: #6c757d;
}

.verified-badge {
  display: inline-block;
  margin-left: 4px;
  color: #3897f0;
  font-size: 14px;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input-spinner {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #888;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}
