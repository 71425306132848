.hero-section {
  flex: 1;
  display: flex;
  background: url('../../assets/hero_bg.png') right/cover;
  color: white;
  position: relative;
}

.hero-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.tag {
  margin-top: 10%;
  background: rgba(255, 255, 255, 0.9);
  color: #ff8c84;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 900;
  font-size: 16px;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hero-accent {
  color: #ffffff;
  font-family: 'Gistesy';
  font-size: 62px;
  margin: 0px 0 15px;
  max-width: 100%;
  text-align: center;
  z-index: -5;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.hero-content h1 {
  margin-top: 40%;
  color: rgb(255, 250, 240);
  text-align: center;
  font-family: Raleway, sans-serif;
  text-transform: uppercase;
  letter-spacing: -1px;
  font-size: 64px;
  font-weight: 900;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.hero-content p {
  color: #333;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6;
}

.hero-image {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  height: 110%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
}

/* Media query for smaller screens (phones) */
@media (max-width: 375px) {
  .tag {
    margin-top: 5% !important;
    font-size: 14px;
  }

  .hero-accent {
    font-size: 44px;
  }

  .hero-content h1 {
    margin-top: 21%;
    font-size: 28px; /* Small phones */
  }

  .hero-image img {
    object-fit: containt;
  }

  .hero-section {
    min-height: 370px;
  }
}

/* Media query for tablets and larger phones */
@media (min-width: 376px) and (max-width: 768px) {
  .hero-section {
    min-height: 65vh !important;
  }
  .hero-content h1 {
    font-size: 40px; /* Tablets and larger phones */
  }
}
