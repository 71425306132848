.who-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.who-item {
  display: flex;
  text-align: left;
  align-items: center;
  gap: 15px;
  margin-top: calc(1.5em / 2);
  margin-bottom: calc(1.5em / 2);
}

.diamond-bullet {
  width: 30px;
  height: 30px;
  margin-top: 5px;
  flex-shrink: 0;
  margin-right: 10px;
}
