.bg2 {
  background-image: url('./bg2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.testimonials-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  flex-wrap: wrap;
}

.testimonial-item {
  flex: 1;
  min-width: 300px;
  max-width: 350px;
}

.testimonial-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 768px) {
  .testimonials-container {
    flex-direction: column;
    align-items: center;
  }

  .testimonial-item {
    width: 100%;
    margin-bottom: 20px;
  }
}
