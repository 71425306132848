.stripe-checkout .App {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.stripe-checkout form {
  width: 30vw;
  min-width: 500px;
  margin: auto;
  padding: 40px;
}

.promo-ending-text {
  color: #d97706 !important;
  margin: 0 !important;
  font-size: 15px !important;
  text-align: center !important;
  margin-bottom: 15px !important;
}

.stripe-checkout #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.stripe-checkout #payment-element {
  margin-bottom: 24px;
}

/* spinner/processing state, errors */
.stripe-checkout .spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* dynamic payment methods annotation */
.stripe-checkout #dpm-annotation {
  align-self: center;
  color: #353a44;
  width: 30vw;
  min-width: 500px;
  line-height: 20px;
  margin-bottom: 20px;
}

.stripe-checkout #dpm-integration-checker {
  display: inline;
  color: #533afd;
}

/* Payment status page */
.stripe-checkout #payment-status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
  width: 30vw;
  min-width: 500px;
  min-height: 380px;
  align-self: center;
  box-shadow:
    0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  opacity: 0;
  animation: fadeInAnimation 1s ease forwards;
  margin-top: auto;
  margin-bottom: auto;
}

.stripe-checkout #status-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.stripe-checkout h2 {
  margin: 0;
  color: #30313d;
  text-align: center;
}

.stripe-checkout a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, sans-serif;
  display: block;
}
.stripe-checkout a:hover {
  filter: contrast(120%);
}

.stripe-checkout #details-table {
  overflow-x: auto;
  width: 100%;
}

.stripe-checkout table {
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
}
.stripe-checkout table tbody tr:first-child td {
  border-top: 1px solid #e6e6e6; /* Top border */
  padding-top: 10px;
}
.stripe-checkout table tbody tr:last-child td {
  border-bottom: 1px solid #e6e6e6; /* Bottom border */
}
.stripe-checkout td {
  padding-bottom: 10px;
}

.stripe-checkout .TableContent {
  text-align: right;
  color: #6d6e78;
}

.stripe-checkout .TableLabel {
  font-weight: 600;
  color: #30313d;
}

.stripe-checkout #view-details {
  color: #0055de;
}

.stripe-checkout #retry-button {
  text-align: center;
  background: #0055de;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fadeInAnimation {
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .stripe-checkout form,
  .stripe-checkout #dpm-annotation,
  .stripe-checkout #payment-status {
    width: 80vw;
    min-width: initial;
  }
}

.stripe-checkout .checkout-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.stripe-checkout .checkout-loader {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.stripe-checkout .product-details {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  margin-bottom: 0.5rem;
  margin-top: 20px;
}

.stripe-checkout .product-box {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 40px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
}

.stripe-checkout .product-box h3 {
  margin: 0 0 16px 0;
  font-size: 16px;
  color: #1a1f36;
}

.stripe-checkout .price-details {
  border-top: 1px solid #e6e6e6;
  padding-top: 16px;
}

.stripe-checkout .price-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  color: #1a1f36;
}

.stripe-checkout .price-row.vat-info {
  color: #757c89;
  font-size: 13px;
}

@media only screen and (max-width: 600px) {
  .stripe-checkout .product-details {
    width: 80vw;
    min-width: initial;
  }

  .stripe-checkout .product-box {
    padding: 20px;
  }
}

.stripe-checkout .form-row {
  margin-bottom: 0.5rem;
}

.stripe-checkout .form-field-container {
  background: #ffffff;
  border-radius: 4px;
  padding: 40px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
  border: 1px solid #e6e6e6;
}

.stripe-checkout .form-field-label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #1a1f36;
}

.stripe-checkout .email-input {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  font-size: 16px;
  color: #1a1f36;
}

.stripe-checkout .email-input:focus {
  outline: none;
  border-color: #0a2540;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.03),
    0px 0px 0px 1px #0a2540;
}

.stripe-checkout .email-input.has-error {
  border-color: #df1b41;
  border-width: 2px;
}

.stripe-checkout .email-input::placeholder {
  color: #697386;
}

.stripe-checkout #email-message {
  color: #df1b41;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}

/* For mobile responsiveness */
@media only screen and (max-width: 600px) {
  .stripe-checkout .form-field-container {
    padding: 20px;
  }
}

.stripe-checkout .future-price-notice {
  margin-top: 10px;
  font-size: 15px;
  color: #697386;
  text-align: right;
}

.stripe-checkout .strike-price {
  position: relative;
  color: #815969;
  margin-left: 4px;
}

.stripe-checkout .strike-price::before,
.stripe-checkout .strike-price::after {
  content: '';
  position: absolute;
  left: 1px;
  right: 1px;
  border-top: 1px solid #df1b41b3;
}

.stripe-checkout .strike-price::before {
  top: 45%;
  transform: rotate(-15deg);
}

.stripe-checkout .strike-price::after {
  top: 45%;
  transform: rotate(15deg);
}

.price-comparison {
  padding: 16px;
  background-color: #fff5f7;
  border: 1px solid #ffe4e9;
  border-radius: 12px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(255, 192, 203, 0.1);
}

.current-price {
  margin-bottom: 12px;
}

.price-label {
  display: block;
  font-size: 15px;
  color: #866b6e;
  font-weight: 300;
  letter-spacing: 0.3px;
}

.price-amount {
  font-size: 26px;
  font-weight: 500;
  color: #4a3436;
  letter-spacing: 0.5px;
}

.original-price .price-amount {
  font-size: 18px;
  color: #9a8487;
}

.strike-price {
  text-decoration: line-through;
  text-decoration-color: #deb1b7;
}

.savings-badge {
  display: inline-block;
  background: linear-gradient(135deg, #f8b4bc, #f4a0aa);
  color: white;
  padding: 6px 16px;
  border-radius: 20px;
  font-size: 15px;
  margin-top: 12px;
  letter-spacing: 0.3px;
  box-shadow: 0 2px 4px rgba(248, 180, 188, 0.2);
}

/* Error Animation */
.error-animation {
  text-align: center;
  padding: 20px;
}

.error-circle {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  border: 4px solid #df1b41;
  position: relative;
  animation: error-circle-appear 0.3s ease-in;
}

.error-icon {
  position: relative;
  height: 80px;
}

.error-line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #df1b41;
  border-radius: 2px;
  top: 37px;
  left: 17px;
}

.error-line-1 {
  transform: rotate(45deg);
  animation: error-line-1 0.75s ease-in-out;
}

.error-line-2 {
  transform: rotate(-45deg);
  animation: error-line-2 0.75s ease-in-out;
}

/* Info Animation */
.info-animation {
  text-align: center;
  padding: 20px;
}

.info-circle {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  border: 4px solid #6d6e78;
  position: relative;
  animation: info-circle-appear 0.3s ease-in;
}

.info-icon {
  position: relative;
  height: 80px;
}

.info-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #6d6e78;
  border-radius: 50%;
  top: 25px;
  left: 36px;
}

.info-line {
  position: absolute;
  width: 8px;
  height: 25px;
  background-color: #6d6e78;
  border-radius: 4px;
  top: 38px;
  left: 36px;
}

@keyframes error-circle-appear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes error-line-1 {
  0% {
    transform: rotate(45deg) scale(0);
  }
  100% {
    transform: rotate(45deg) scale(1);
  }
}

@keyframes error-line-2 {
  0% {
    transform: rotate(-45deg) scale(0);
  }
  100% {
    transform: rotate(-45deg) scale(1);
  }
}

@keyframes info-circle-appear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
