@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@font-face {
  font-family: 'Gistesy';
  src: url('./assets/Gistesy.woff') format('woff');
}

body {
  background-color: #ffefe5;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  min-width: 375px;
}

.info-section {
  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  margin: auto 0;
}

.info-section h2 {
  color: #fffaf0;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin: 1.5rem;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.info-section .subtitle {
  color: #fff5ee;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.info-section .description {
  color: #fff5ee;
  text-align: center;
}

.highlight {
  color: #d67688;
}

.main-message {
  color: #3d3d3d;
  font-family: Poppins, sans-serif;
  font-size: 27px;
  font-weight: 900;
  letter-spacing: -1.1px;
  line-height: 32px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.main-heading {
  text-align: center;
  color: #3b3838;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-size: 33px;
  line-height: 1.2;
  text-transform: uppercase;
  margin: 30px 0;
  letter-spacing: -1px;
  text-shadow: 2px 5px 3px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

.strong {
  color: #d67688;
  font-weight: 900;
  text-transform: uppercase;
}

.accent-text {
  color: #d67688;
  font-family: 'Gistesy';
  font-size: 55px;
  margin: 15px 0;
  max-width: 100%;
  text-align: center;
}

.script-text {
  font-family: 'Dancing Script', cursive;
  font-size: 36px;
  color: #333333;
  margin: 15px 0;
}

.regular-text {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 1.6;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.bg-section {
  background: url('./assets/hero_bg_light.png') right bottom/cover;
  box-sizing: border-box;
  padding: 50px 10%;
  width: 100%;
}

.bg-section1 {
  background: url('./assets/1.png') right bottom/cover;
  box-sizing: border-box;
  padding: 50px 10%;
  width: 100%;
}

.bg-section2 {
  background: url('./assets/2.png') center bottom/cover;
  box-sizing: border-box;
  padding: 50px 10%;
  width: 100%;
}

.problems-list {
  list-style: none;
  padding: 0;
  margin: 30px auto;
}

.problems-list li {
  position: relative;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #3b3838;
  text-align: left;
  transition: transform 0.2s ease;
  padding: 20px 20px 20px 70px;
}

.problems-list li:before {
  content: '✓';
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #d67688;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(214, 118, 136, 0.3);
}

.problems-list li:hover {
  transform: translateX(5px);
  background: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .info-section {
    min-height: 40vh;
    padding: 30px 5%;
  }

  .info-section h2 {
    font-size: 28px;
  }

  .bg-section {
    padding: 30px 5%;
    min-height: 300px;
  }

  .problems-list li {
    font-size: 15px;
    padding: 15px 15px 15px 50px;
  }
}

.powerful-text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 1.5rem 0;
  color: #333;
}

.highlight-box {
  background: linear-gradient(45deg, #ffd6e7, #fff0f5);
  padding: 2rem;
  border-radius: 15px;
  margin: 2rem 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  max-width: 900px;
  margin: 0 auto;
}

.benefit-item {
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.benefit-icon {
  font-size: 1.5rem;
  flex-shrink: 0;
}

.highlight {
  color: #f0c3e2;
  font-weight: bold;
}

.cta-section {
  text-align: center;
  margin: 3rem 0;
}

.transformation-section {
  padding: 4rem 2rem;
  background: linear-gradient(180deg, #ffefe5 0%, #ffdfd1 100%);
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.benefit-card {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.05);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.benefit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
}

.benefit-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #ffd6e7, #fff0f5);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.benefit-card h3 {
  color: #333;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.benefit-card p {
  color: #666;
  line-height: 1.6;
  font-size: 1rem;
}

.highlight-box-premium {
  background: white;
  padding: 3rem;
  border-radius: 25px;
  margin: 3rem 0;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.05);
}

.centered {
  text-align: center;
}

.pricing {
  padding-top: 20px;
}

.pricing-section {
  background: linear-gradient(180deg, #ffefe5 0%, #ffdfd1 100%);
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 25px;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 20px 2rem 50px;
}

.pricing-box {
  background: white;
  border-radius: 25px;
  padding: 3rem;
  width: calc(50% - 25px);
  max-width: 500px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .pricing-section {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .pricing-box {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 2rem;
    height: auto;
  }
}

.pricing-header {
  background: linear-gradient(135deg, #ffd6e7 0%, #ffb4a2 100%);
  padding: 2rem;
  margin: -3rem -3rem 2rem -3rem;
  border-radius: 25px 25px 0 0;
  text-align: center;
  box-sizing: border-box;
}

.pricing-title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.pricing-subtitle {
  font-size: 1.2rem;
  color: #666;
}

.price-container {
  text-align: center;
  margin: 2rem 0 0;
  position: relative;
}

.price-column {
  display: inline-block;
  text-align: center;
  position: relative;
}

.price-label {
  font-size: 1rem;
  color: #888;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

.current-price {
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 1rem;
  transition: color 0.3s ease;
}

.future-price {
  font-size: 1.5rem;
  color: #999;
  margin-bottom: 0.5rem;
  text-decoration: line-through;
}

.savings-label {
  font-size: 1rem;
  color: #d67688;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.savings-amount {
  font-size: 1.8rem;
  font-weight: 800;
  letter-spacing: 1px;
}

.promo-banner {
  text-align: center;
  margin: 0 0 2rem;
  color: #d67688;
  font-weight: 600;
}

.promo-icon {
  margin: 0 0.5rem;
}

@media (max-width: 768px) {
  .current-price {
    font-size: 2rem;
  }

  .future-price {
    font-size: 2.5rem;
  }

  .savings-badge {
    padding: 0.8rem 1.5rem;
  }

  .savings-amount {
    font-size: 1.5rem;
  }

  .pricing-header {
    margin: -2rem -2rem 2rem -2rem;
    padding: 1.5rem;
  }
}

.pricing-features {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
}

.pricing-features li {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1.5rem;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.pricing-features li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #d67688;
  font-weight: bold;
}

.pricing-features .highlight {
  font-weight: 600;
  display: block;
  margin-top: 0.5rem;
}

.pricing-cta {
  background: #e6b5be;
  color: white;
  border: none;
  padding: 1.2rem 2.5rem;
  font-size: 1.1rem;
  border-radius: 50px;
  transition: all 0.3s ease;
  display: block;
  margin: 0 auto;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: fit-content;
}

.pricing-cta:hover {
  background: #d67688;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(214, 118, 136, 0.4);
}

.future-price {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  color: #999;
  margin-bottom: 0.5rem;
  text-decoration: line-through;
}

.price-note {
  font-size: 0.9rem;
  font-weight: normal;
  color: #666;
  margin-left: 0.5rem;
}

.promo-text {
  text-align: center;
  color: #d67688;
  font-weight: 600;
  font-size: 1rem;
  margin: 1rem 0 2rem;
}

.savings-header {
  background: linear-gradient(135deg, #ffd6e7 0%, #ffb4a2 100%);
  color: #333;
  padding: 1.5rem;
  margin: -2rem -2rem 2rem -2rem;
}

.savings-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
}

.savings-amount {
  font-size: 1.1rem;
  letter-spacing: 1px;
  font-weight: 500;
}

.transformation-benefits {
  background: linear-gradient(135deg, #fff8f6 0%, #fff2ed 100%);
  padding: 7rem 2rem;
  position: relative;
  overflow: hidden;
}

.transformation-benefits::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
      circle at 20% 20%,
      rgba(255, 214, 231, 0.15) 0%,
      transparent 50%
    ),
    radial-gradient(
      circle at 80% 80%,
      rgba(255, 214, 231, 0.15) 0%,
      transparent 50%
    );
}

.elegant-heading {
  font-family: 'Gistesy', serif;
  font-size: 3rem;
  color: #d67688;
  text-align: center;
  margin-bottom: 5rem;
  position: relative;
}

.elegant-heading::after {
  content: '';
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  color: #d67688;
  opacity: 0.5;
}

.benefits-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 2rem;
}

.benefit-item {
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  line-height: 1.9;
  color: #333;
  padding-left: 3rem;
  position: relative;
  transition: transform 0.3s ease;
}

.benefit-item:hover {
  transform: translateX(10px);
}

.mystical-icon {
  color: #d67688;
  font-size: 1.4rem;
  opacity: 0.8;
  display: block;
  text-align: center;
}

.highlight {
  color: #d67688;
  font-weight: 600;
  position: relative;
  display: inline-block;
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent, #d67688, transparent);
  opacity: 0.3;
}

.testimonial-heading {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  color: #333;
  margin: 6rem 0;
  letter-spacing: 2px;
  position: relative;
}

.testimonial-heading::before,
.testimonial-heading::after {
  content: '✧';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #d67688;
  opacity: 0.5;
}

.testimonial-heading::before {
  left: 0;
}

.testimonial-heading::after {
  right: 0;
}

.closing-message {
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  line-height: 1.9;
  color: #333;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  backdrop-filter: blur(10px);
}

.invitation {
  font-family: 'Gistesy', serif;
  font-size: 50px;
  color: #d67688;
  line-height: 1.2;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .transformation-benefits {
    padding: 3rem 1.5rem;
  }

  .elegant-heading {
    font-size: 2rem;
  }

  .benefit-item {
    font-size: 1rem;
  }
}

.benefits-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
  text-align: center;
}

.benefit-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flower-divider {
  text-align: center;
  margin: 1rem 0;
  font-size: 1.2rem;
  opacity: 0.8;
}

.spacing-divider {
  height: 2rem;
  margin: 0;
}

.divider {
  width: 300px;
  height: 20px;
  margin: 2rem auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider::before,
.divider::after {
  content: '';
  height: 1px;
  width: 120px;
  background: linear-gradient(90deg, transparent, #d67688, transparent);
}

.divider::after {
  transform: scaleX(-1);
}

.divider span {
  margin: 0 15px;
  color: #d67688;
  font-size: 16px;
}

.cta-button {
  background: linear-gradient(
    135deg,
    #ffb5be 0%,
    #ffa07a 50%,
    /* Pomarańczowy odcień */ #fbeaa9 100%
  );
  color: #333;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  position: relative;
  border-radius: 12px;
  transition: all 0.3s ease;
  font-weight: bold;
  box-shadow: 0 4px 15px rgba(251, 234, 169, 0.3);
  min-width: 100px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-button:hover {
  transform: scale(1.05);
  box-shadow:
    0 6px 20px rgba(251, 234, 169, 0.4),
    0 4px 8px rgba(251, 234, 169, 0.3);
}

.cta-button:hover::before {
  border-color: #d4af37; /* Pełna złota obwódka na hover */
  opacity: 1;
}

.cta-button .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px; /* Center the spinner */
  border: 3px solid transparent;
  border-top: 3px solid #fff; /* Color of the spinner */
  border-right: 3px solid #fff;
  border-radius: 50%;
  animation: dual-ring-spin 1s linear infinite;
}

@keyframes dual-ring-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble-cta {
  0%,
  100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(3deg);
  }
}

.wobble {
  animation: wobble-cta 0.4s ease;
}

@keyframes wobble-error {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-8px);
  }
  75% {
    transform: translateX(8px);
  }
}

.wobble-error {
  animation: wobble-error 0.4s ease;
}

/* Make sure inputs maintain their layout while wobbling */
.form-input,
.checkbox-container {
  position: relative;
  z-index: 1;
}

.desktop-break {
  display: none;
}

@media (min-width: 768px) {
  .desktop-break {
    display: block;
  }
}

.xs-break {
  display: none;
}

@media (max-width: 375px) {
  .regular-text {
    font-size: 16px;
  }
  .xs-break {
    display: block;
  }
}
